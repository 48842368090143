/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import "../../@core/scss/base/bootstrap-extended/include";
@import "../../@core/scss/base/components/include";

// loader
.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.dropzone {
  min-height: auto !important;
}

.blog-post {
  &__headline {
    font-size: 1.25em;
    font-weight: bold;
  }

  &__meta {
    font-size: 0.85em;
    color: #6b6b6b;
  }
}

// OBJECTS

.o-media {
  display: flex;

  &__body {
    flex-grow: 1;
    margin-left: 1em;
  }
}

.o-vertical-spacing {
  > * + * {
    margin-top: 0.75em;
  }

  &--l {
    > * + * {
      margin-top: 2em;
    }
  }
}

// loader
html {
  .content.app-content {
    padding: calc(38px) 2rem 0;
  }
}

// [dir] .flatpickr-input[readonly] {
//   cursor: default;
// }

.datepointer {
  cursor: pointer !important;
}
[dir] .form-control.datepointer:disabled {
  cursor: default !important;
}
[dir] .form-control:disabled {
  background-color: #efefef !important;
  cursor: default;
}

.common {
  min-width: 50px;
  // width: 112px;
  height: 46px;
  font-weight: 900;
  font-size: 14px;
  border-radius: 24px;
  color: #2d2d2d !important;
  font-family: "Source Sans Pro";
  padding: 5px;
  letter-spacing: 1px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}
.cancelbtn {
  min-width: 50px;
  width: 103px;
  height: 46px;
  font-weight: 900;
  font-size: 14px;
  border-radius: 24px;
  color: #2d2d2d !important;
  font-family: "Source Sans Pro";
  padding: 5px;
  letter-spacing: 1px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #b9b9c3 !important;
}
.cancelbtn.add-new-area {
  background-color: transparent !important;
  width: 153px;
  :hover {
    background-color: rgba(130, 134, 139, 0.04) !important;
  }
}

.common.savebtn {
  width: 83px;
}

.common.deletebtn {
  background: #ea5455;
  width: 101px;
  color: #fff !important;
  text-transform: uppercase;
}

.form-control {
  color: $darkblack;
  font-family: $font-family-source-sans;
}
.form-control:focus {
  color: $darkblack;
}
.login_title {
  font-family: $font-family-source-sans;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 100%;
  color: #2d2d2d;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 30px;
  span {
    margin-top: 6px;
    font-size: 32px;
  }
}
.auth-wrapper .auth-inner {
  width: 100%;
  margin-left: 30px !important;
  margin-right: 0px !important;
}
.auth-image {
  height: 100vh;
  width: 100%;
}
.auth-wrapper.auth-cover {
  max-width: 1440px;
  margin: 0 auto;
}
.auth-wrapper {
  .auth-bg {
    background-color: #fff;
    margin-left: -30px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
}

.form-label {
  font-family: $font-family-source-sans;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
}

// Vertical menu
.main-menu {
  overflow: visible;
}
.main-menu.menu-light .navigation li a {
  color: #ffffff;
}

.main-menu.menu-light {
  background: $darkblack;
}
.main-menu.menu-light .navigation {
  background: #2d2d2d;
  margin-top: 21px;
}

.main-menu .shadow-bottom {
  display: none;
  background: none;
}
.toggal_svg {
  background: #fff;
  border-radius: 50%;
  color: #58595b;
}
.main-menu .navbar-header .modern-nav-toggle {
  position: absolute;
  right: -17px;
  top: 37px;
  z-index: 999;
}
.main-menu .navbar-header {
  height: 4.9rem;
}
.main-menu.menu-light.expanded .navigation > li > a {
  margin: 0 0;
}
.vertical-layout .main-menu .navigation a.active {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: none;
  border-left: 3px solid $primary;
  border-radius: 0;
  svg {
    color: $primary;
    path {
      fill: $primary;
    }
  }
  & #database {
    path {
      fill: none;
    }
  }
}
.vertical-layout.vertical-menu-modern .main-menu {
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
}

.main-menu .navbar-header .navbar-brand .brand-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  color: #ffffff;
}
.main-menu .main-menu-content {
  border-top: 1px solid rgb(255 255 255 / 20%);
  height: calc(100% - 10rem) !important;
}
.navbar-header.nav-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.nav-footer .nav-item.navbar-brand {
  // width: 100%;
  margin-right: 0;
}
.nav-footer .user-info {
  display: flex;
  align-items: center;
  // width: 100%;
}
.nav-footer.navbar-header .navbar-brand {
  // width: 100%;
  justify-content: space-between;
  margin-right: 0;
}
.main-menu .hide-arrow {
  display: none;
}
.main-menu.expanded .hide-arrow {
  display: flex;
  // transition: 300ms ease all, background 0s;
}
.main-menu .navbar-header {
  width: auto;
}

.main-menu .navbar-header.nav-footer .navbar-brand .brand-text {
  font-weight: 400;
  font-size: 16px;
}
// users table

div.table-block tbody {
  background-color: transparent;
}

.page-title {
  h3 {
    font-family: $font-family-source-sans;
    font-weight: 900;
    font-size: 24px;
    color: #2d2d2d;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 24px;
  }
  p {
    font-family: $font-family-source-sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #808080;
  }
}
.table-block {
  border: 1px solid #b9b9c3;
  border-radius: 8px;

  thead th:first-child {
    border-top-left-radius: 8px;
  }
  thead th:last-child {
    border-top-right-radius: 8px;
  }
  tbody {
    font-weight: 400;
    font-size: 16px;
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
  tbody td {
    padding: 1rem 2rem;
    color: #2d2d2d;
  }
  .td-text-color {
    color: #2d2d2d;
  }
}
// .action-btn.delete{
// 	:hover{
// 		// width: 18px;
// 		// height: 18px;
// 		background-color: #c7c7d379 ;
// 		border-radius: 50%;
// 		box-shadow: none;
// 		padding: 3px;
// 		min-width: 20px;
// 		min-height: 20px;

// 	}

// }

button.action-btn,
.action-btn[type="button"] {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  padding: 7px;
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%;
}
button.action-btn:hover,
button.action-btn:active,
.action-btn:hover[type="button"] {
  background-color: rgb(185 185 195 / 20%) !important;
  outline: 0 !important;
}
svg:focus {
  outline: 0 !important;
}
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active {
  background-color: transparent !important;
}
.action-btn.btn-secondary:hover:not(.disabled):not(:disabled) {
  box-shadow: none;
}

button.back-btn,
.back-btn[type="button"] {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  padding: 7px;
  // width: 32px;
  // height: 32px;
  display: inline-block;
  border-radius: 50%;
  color: #2d2d2d !important;
}
button.back-btn:hover,
button.back-btn:active,
.back-btn:hover[type="button"] {
  background-color: rgb(185 185 195 / 20%) !important;
  outline: 0 !important;
  color: #2d2d2d !important;
}
svg:focus {
  outline: 0 !important;
}
.back-btn.btn-secondary:hover:not(.disabled):not(:disabled) {
  box-shadow: none;
}

// Modal
.modal-box {
  width: 670px;
  margin: 0 auto;
}
.modal-heading-box {
  h1 {
    font-family: $font-family-source-sans;
    font-weight: 900;
    font-size: 28px;
    color: $darkblack;
  }
  p {
    font-family: $font-family-source-sans;
    font-weight: 400;
    font-size: 16px;
    color: #808080;
  }
}

// Delete Modal

.delete-modal {
  p {
    font-size: 16px;
  }
  span {
    color: $darkblack;
    font-weight: 600;
  }
}

.Report-select {
  z-index: 999;
}

.location-select {
  z-index: 999;
  max-width: 350px;
}
.Select-Location {
  .fc-view-harness {
    border: 1px solid var(--fc-border-color, #ddd);
  }
  .fc-view {
    display: none;
  }
  .calenderIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .calenderIcon p {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #82868b;
    margin-top: 20px;
  }
}

.fc .fc-toolbar .fc-toolbar-title {
  margin-left: 0.25rem;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #2d2d2d;
}

.eventDescription {
  svg {
    margin-right: 5px;
  }
}

// Location

.card .card-title {
  font-family: $font-family-source-sans;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: $darkblack;
}

.modal.event-sidebar {
  .modal-header {
    background-color: #ea5455;
    border-radius: 0;
    color: #fff;
    // .event-sidebar {
    // }
  }
  .modal-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #fff;
  }
}

.no_user {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  p {
    margin: 0;
    padding: 15px;
    font-weight: 700;
    // text-align: center;
    font-size: 16px;
  }
}
// Reports

.reports {
  .avatar.bg-light-primary {
    background: $primary !important;
  }
  h2 {
    font-family: $font-family-source-sans;
    font-weight: 900 !important;
    color: $darkblack;
    font-size: 28px;
  }
  p {
    font-family: $font-family-source-sans;
    font-weight: 600;
    font-size: 15px;
    color: #6e6b7b;
  }
  .card-body {
    padding: 12px 15px 12px 15px;
  }
}

.report-select {
  min-width: 350px;
}

// Home

.home-tabs {
  .nav-link {
    font-family: $font-family-source-sans;
    background-color: #efefef;
    font-weight: 700;
    color: #b9b9c3;
    margin: 0 10px 0 0;
    :hover {
      color: #b9b9c3;
    }
  }
  .nav-link.active {
    color: $darkblack;
    :hover {
      color: $darkblack;
    }
  }
}

.homeselectcard {
  height: calc(100vh - 200px);
  .homeauctionicon {
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    // text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      color: #82868b;
      margin-top: 20px;
    }
  }
}

.home_print_reasult {
  Label {
    color: $darkblack !important;
  }
}

.csv-modal h2 {
  font-size: 21px;
  color: $darkblack !important;
  font-family: $font-family-source-sans;
  font-weight: 900;
}

.cancelbtn.markdone {
  font-family: $font-family-source-sans;
  width: 109px;
  font-size: 11px;
  font-weight: 700;
  height: 28px;
  color: $darkblack !important;
}
.cancelbtn.donebtn {
  font-family: $font-family-source-sans;
  width: 109px;
  font-size: 11px;
  font-weight: 700;
  color: #fff !important;
  height: 28px;
  background-color: #28c76f !important;
  // opacity: 1;
  pointer-events: none;
}

.Table-entry-select {
  select {
    width: 5rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .form-label {
    font-weight: 400 !important;
    color: $darkblack;
  }
}
.avatar .avatar-content {
  color: #2d2d2d;
}

.table {
  border-radius: 8px;
}
.DownloadLoder {
  position: fixed;
  right: 30px;
  bottom: 90px;
  min-width: 120px;
  height: 50px;
  z-index: 9999;
  background: rgb(193, 163, 87);
  border-radius: 50px;
  padding: 10px 15px;
  display: flex;
  align-content: center;
  align-items: center;
}
.SpinnerText {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: 600;
  .filename {
    display: block;
    margin: 0 0;
    padding: 0 0;
    font-size: 13px;
  }
}
// .fc-event:focus {
//   background: transparent;
//   box-shadow: none;
// }

// [dir] .fc-event:focus {
//   background: transparent;
//   box-shadow: none;
// }

// [dir] .fc-event-selected:after,
// [dir] .fc-event:focus:after {
//   // background: rgba(0, 0, 0, 0.25);
//   background: transparent;
// }
